<template>
	<div
		v-for="action in actionOptions"
		:key="action.id"
		class="group"
	>
		<div class="flex items-center flex-shrink-0 px-2 -mx-2 rounded-lg group-hover:bg-white/80 dark:group-hover:bg-mx-green-800">
			<div class="relative flex items-center flex-shrink-0">
				<!-- TODO i18n -->
				<input
					:id="action.id"
					v-model="selectedAction"
					type="radio"
					:value="action.id"
					class="transition-all duration-100 bg-mx-gray-200 border-2 border-mx-gray-400 appearance-none h-4 w-4 rounded-full cursor-pointer group-has-[:checked]:bg-mx-orange group-has-[:checked]:border-mx-orange text-mx-orange ring-mx-orange focus:ring-mx-orange group-hover:bg-mx-gray-100 dark:group-hover:bg-mx-green-900 dark:bg-mx-green-900 group-has-[:checked]:dark:bg-mx-orange"
				>
				<div class="absolute left-[5px] top-[5px] w-1.5 h-1.5 bg-transparent group-has-[:checked]:bg-white dark:group-has-[:checked]:bg-mx-green-900 rounded-full transform transition-transform duration-100" />
			</div>

			<label
				:for="action.id"
				class="flex flex-col items-start w-full gap-4 py-4 pl-4 cursor-pointer lg:flex-row dark:text-mx-gray-400 lg:items-center"
			>
				<span class="font-semibold lg:w-72 dark:text-mx-gray-300">
					<!-- TODO i18n -->
					{{ action.label }}
				</span>
				<span v-html="action.description" />
			</label>
		</div>
	</div>
</template>

<script setup lang="ts">
import type { BatchActionOption } from '@/components/table/types'

defineProps({
	actionOptions: {
		type: Object as PropType<BatchActionOption[]>,
		required: true
	}
})

const selectedAction = defineModel<string | null>({ default: null })

</script>
